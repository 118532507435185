import React, { useState, useEffect } from "react";
import ClientCountryMessage, { instanceOfClientCountryMessage } from "shared-models/build/client/countries";
import ClientOperatorMessage, { getDefaultClientOperatorMessage } from "shared-models/build/client/operators";
import TourOperatorProfile from "../../../templates/tour-operator";
import { sendGenericPostRequest } from "../../../util/util";
const defaultOperator = getDefaultClientOperatorMessage();

interface Props {
    id: string,
    name: string,
}

const TourOperatorPage = ({ id, name }: Props) => {
    const [operator, setOperator] = useState<ClientOperatorMessage>(defaultOperator);
    const [countries, setCountries] = useState<{ [key: number]: ClientCountryMessage }>({});
    useEffect(() => {
        getCountryData();
        if (id) {
            const numberId = +id;
            if (isNaN(numberId)) return;
            getOperatorData(numberId);
        }
    }, []);

    const getOperatorData = async (id: number) => {
        const [resp, err] = await sendGenericPostRequest("/organization/get-operator-with-id", { OperatorId: id }, "", "Sorry something went wrong, please contact our team.")
        if (err !== null) {
            console.log({ resp, err });
        } else {
            setOperator(resp);
        }
    }

    const getCountryData = async () => {
        // Get Verified Countries
        const [verifiedCountries, errTwo] = await sendGenericPostRequest("/country/get-verified-countries", {}, "", "");
        if (errTwo !== null || !Array.isArray(verifiedCountries)) {
            console.log({ verifiedCountries, errTwo });
        } else {
            const countryMap = verifiedCountries.reduce((obj: { [key: number]: ClientCountryMessage }, cnt) => {
                if (!instanceOfClientCountryMessage(cnt)) return obj;
                return {
                    ...obj,
                    [cnt.CountryId]: cnt,
                }
            }, {});
            setCountries(countryMap);
        }
    }

    return (
        <TourOperatorProfile
            pageContext={{
                operator: operator,
                countries: countries,
            }}
        />   
    )
}

export default TourOperatorPage;