"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfClientCountryMessage = void 0;
;
const instanceOfClientCountryMessage = (object) => {
    return ('Name' in object &&
        'Description' in object &&
        'BestMonthsToTravel' in object &&
        'AverageCost' in object &&
        'Currency' in object &&
        'Activities' in object &&
        'ImageURL' in object &&
        'BlogPostLink' in object &&
        'CountryId' in object &&
        'FlagUrl' in object);
};
exports.instanceOfClientCountryMessage = instanceOfClientCountryMessage;
